import Vue from 'vue'
import Vuex from 'vuex'

Vue.use( Vuex )

export default new Vuex.Store({
    state: {
        irisScanFailed: false,
        stream: null
    },
    getters: {
        irisScanFailed: state => state.irisScanFailed,
        userStream: state => state.stream
    },
    mutations: {
        irisScanFailed( state, value ) {
            Vue.set( state, 'irisScanFailed', value )
        },
        setStream( state, value ) {
            Vue.set( state, 'stream', value )
        }
    },
    actions: {
    }
} )
