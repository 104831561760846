<template>
    <div class="ck-start ck-view">
        <div class="ck-start__intro ck-text-block">
            <h1 v-if="irisScanFailed">Iris Scan Failed</h1>
            <button class="ck-button" @click="accessHub">Access</button>
            <h1>Execution Hub</h1>
        </div>
    </div>
</template>

<script>

export default {
    name: 'Start',
    computed: {
        irisScanFailed() {
            return this.$store.state.irisScanFailed
        }
    },
    methods: {
        accessHub() {
            this.$router.push( '/connect' )
        }
    },
    mounted() {
    }
}
</script>

<style lang="scss">
.ck-start {
    justify-content: center;

    .ck-button {
        margin: 2rem 0;
    }
}
</style>
