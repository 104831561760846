const videoConfig = [
    {
        autoplay: false,
        controls: false,
        preload: 'auto',
        muted: true,
        sources: [
            {
                src: "https://firebasestorage.googleapis.com/v0/b/test-a3e2b.appspot.com/o/videos%2Freference-video-1.mp4?alt=media&token=0357e0a3-bbec-436a-be3d-a2c676525018",
                type: "video/mp4"
            }
        ]
    },
    {
        autoplay: false,
        controls: false,
        preload: 'auto',
        muted: true,
        sources: [
            {
                src: "https://firebasestorage.googleapis.com/v0/b/test-a3e2b.appspot.com/o/videos%2Freference-video-2.mp4?alt=media&token=38adc521-95a9-42fd-9371-49d93d5205c9",
                type: "video/mp4"
            }
        ]
    },
    {
        autoplay: false,
        controls: false,
        preload: 'auto',
        muted: true,
        sources: [
            {
                src: "https://firebasestorage.googleapis.com/v0/b/test-a3e2b.appspot.com/o/videos%2Freference-video-3.mp4?alt=media&token=220d9772-1330-4860-911a-a989808698ce",
                type: "video/mp4"
            }
        ]
    },
    {
        autoplay: false,
        controls: false,
        preload: 'auto',
        muted: true,
        sources: [
            {
                src: "https://firebasestorage.googleapis.com/v0/b/test-a3e2b.appspot.com/o/videos%2Freference-video-4.mp4?alt=media&token=75ab4e7c-1e93-4fb5-9976-02efb0af5c45",
                type: "video/mp4"
            }
        ]
    }
]

export default videoConfig