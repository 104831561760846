import Vue from 'vue'
import VueRouter from 'vue-router'

import Start from '@/views/Start.vue'
import Access from '@/views/Access.vue'
import Call from '@/views/Call.vue'
import Connect from '@/views/Connect'

Vue.use( VueRouter )

const routes = [
    {
        path: '/',
        name: 'Start',
        component: Start
    },
    {
        path: '/connect',
        name: 'Connect',
        component: Connect
    },
    {
        path: '/access',
        name: 'Access',
        component: Access
    },
    {
        path: '/call',
        name: 'Call',
        component: Call
    }
]

const router = new VueRouter( {
    mode: 'history',
    base: process.env.BASE_URL,
    routes
} )

export default router
