<template>
    <div class="ck-connect ck-view">
        <div class="ck-connect__intro ck-text-block">
            <h1 v-if="!connectionEstablished">Establish Connection</h1>
            <h1 v-else>Connection Established</h1>
            <p class="ck-intro-text" v-if="!connectionEstablished">Unscramble the signal flow by moving the bits to their correct locations.</p>
        </div>
        <div class="ck-connect__puzzle-container" v-if="!connectionEstablished">
            <img id="ck-puzzle-image" ref="puzzleImage" alt="Puzzle Image" src="../assets/start-puzzle-v2.png" @load="initImage">
        </div>
        <div class="ck-connect__solve-puzzle" @click="solvePuzzle"></div>
        <transition name="fade">
            <div class="ck-connect__animation-container" v-show="connectionEstablished">
                <img src="../assets/loading-v2.gif" alt="Loading GIF">
            </div>
        </transition>
    </div>
</template>

<script>
import snapfit from 'snapfit'

export default {
    name: 'Connect',
    data() {
        return {
            connectionEstablished: false
        }
    },
    methods: {
        puzzleSolved() {
            this.connectionEstablished = true
            setTimeout( () => {
                this.$router.push( '/access' )
            }, 7000 )
        },
        initImage() {
            const imageElement = document.querySelector( '#ck-puzzle-image' )

            snapfit.defaultMixed = true
            snapfit.defaultLevel = 2
            snapfit.defaultSimple = true

            snapfit.add( imageElement, {
                callback: this.puzzleSolved
            } )
        },
        solvePuzzle() {
            const imageElement = document.querySelector( '#ck-puzzle-image' )

            snapfit.solve( imageElement )
            setTimeout( () => {
                this.$router.push( '/access' )
            }, 2000 )
        }
    }
}
</script>

<style lang="scss">
.ck-connect {
    &__puzzle-container {
        width: 300px;
        height: 300px;
        margin: 2rem 0;

        img {
            width: 100%;
            height: auto;
        }
    }

    &__animation-container {
        margin: 3rem 0;
    }

    &__solve-puzzle {
        position: absolute;
        left: 2rem;
        bottom: 2rem;
        width: 100px;
        height: 100px;
        border-radius: 50%;
        cursor: pointer;

        &:hover {
            box-shadow: 2px 2px 10px white;
        }
    }
}
</style>