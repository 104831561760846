<template>
    <div class="ck-call ck-view">
        <div class="ck-connect__intro ck-text-block">
            <h1>Execution Hub</h1>
        </div>
        <div class="ck-call__main-container">
            <div class="ck-call__aside">
                <h3>Special Forces</h3>
                <ul class="ck-call__link-list">
                    <li
                        class="ck-call__link"
                        v-for="( link, index ) in contentConfig.links"
                        :key="index"
                        @click="setContent( link.content )">
                        <span>{{ link.name }}</span>
                    </li>
                </ul>
            </div>
            <div class="ck-call__main">
                <div class="ck-call__action-window">
                    <div class="ck-placeholder-container" v-show="!mainVideoVisible"></div>
                    <ck-video class="ck-video--disabled" ref="mainVideo" :options="mainVideoOptions" :disabled="true" v-show="mainVideoVisible"></ck-video>
                    <div class="ck-video-container" v-show="webcamVideoVisible">
                        <video id="ck-webcam-video" ref="webcamVideo" :src="userStream"></video>
                    </div>
                    <button id="ck-join-call" class="ck-button" @click="joinCall" v-show="!callStarted">Join Video Chat</button>
                </div>
            </div>
            <div class="ck-call__aside">
                <h3>Users Online</h3>
                <ul class="ck-call__link-list">
                    <li
                        class="ck-call__person"
                        v-for="( person, index ) in contentConfig.persons"
                        :key="index"
                        @click="setContent( person.content, person.image )">
                        <span>{{ person.name }}</span>
                    </li>
                    <li class="ck-call__person" v-if="!webcamVideoVisible" @click="setContent( contentConfig.noprofile )">
                        <span>@NEW_USER</span>
                    </li>
                    <li class="ck-call__person" style="color: white;" v-if="webcamVideoVisible" @click="setContent( contentConfig.noprofile )">
                        <span>@EggVoid22</span>
                    </li>
                </ul>
            </div>
        </div>
        <transition name="slide-fade">
            <div class="ck-call__overlay" v-show="showContentOverlay">
                <div class="ck-call__overlay-inner">
                    <div class="ck-call__overlay-closer" @click="closeOverlay">close X</div>
                    <div class="ck-call__overlay-content" v-html="selectedContent"></div>
                    <div class="ck-call__overlay-image" v-if="selectedImage">
                        <img :src="selectedImage" alt="">
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import videoConfig from '@/service/videoConfig'
import contentConfig from '@/service/contentConfig'

import VideoComponent from '@/components/VideoComponent'

export default {
    name: 'Call',
    components: {
        'ck-video': VideoComponent
    },
    data() {
        return {
            videoConfig,
            contentConfig,
            callStarted: false,
            mainVideoVisible: false,
            mainVideoOptions: {
                autoplay: false,
                controls: false,
                preload: 'auto',
                muted: false,
                sources: [
                    {
                        src: 'https://firebasestorage.googleapis.com/v0/b/test-a3e2b.appspot.com/o/videos%2Fkillercall.mp4?alt=media&token=c07f4686-55d7-4dc3-af77-abdc05ba20b1',
                        type: 'video/mp4'
                    }
                ]
            },
            selectedContent: null,
            selectedImage: null,
            showContentOverlay: false,
            webcamVideoVisible: false
        }
    },
    computed: {
        userStream() {
            return this.$store.getters.videoStream
        }
    },
    methods: {
        joinCall() {
            this.$refs.mainVideo.playVideo()
            this.mainVideoVisible = true
            this.callStarted = true
        },
        setMainVideo( options ) {
            this.$refs.mainVideo.setCurrentSource( options.sources.src )
            this.$refs.mainVideo.setCurrentTime( options.time )
            this.$refs.mainVideo.playVideo()

            this.mainVideoVisible = true
        },
        setContent( content, image = null ) {
            this.showContentOverlay = true
            this.selectedContent = content
            this.selectedImage = image
        },
        closeOverlay() {
            this.showContentOverlay = false
        },
        setWebcamVideo() {
            this.webcamVideoVisible = true
        },
        resetActionWindow() {
            this.mainVideoVisible = false
            this.webcamVideoVisible = false
        }
    },
    mounted() {
        this.$on( 'playerData', this.setMainVideo )
        this.$on( 'playerTimeEvent', this.setWebcamVideo )
        // this.$on( 'playerFinishedEvent', this.resetActionWindow )

        navigator.mediaDevices.getUserMedia({ audio: false, video: true } )
            .then( ( stream ) => {
                this.$refs.webcamVideo.srcObject = stream
                this.$refs.webcamVideo.onloadedmetadata = () => {
                    this.$refs.webcamVideo.play()
                }
            } )
            .catch( () => {
                //
            } )
    }
}
</script>

<style lang="scss">
.ck-call {
    padding-bottom: 3rem;

    #ck-join-call {
        position: absolute;
        bottom: 1rem;
        left: 0;
        right: 0;
        margin: 0 auto;
    }

    .ck-video-container,
    .ck-placeholder-container {
        width: 100%;
        padding-top: 56.25%;
        position: relative;
    }

    .ck-placeholder-container {
        position: absolute;
        top: 0;
        left: 0;
        background: lightgrey;
        opacity: .5;
    }

    #ck-webcam-video {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        margin: 0 auto;
        width: 280px;
        height: auto;
        transform: translateY(50%);
        z-index: 20;
    }

    &__main-container {
        display: flex;
        flex-grow: 1;
        width: 100%;
        margin-top: 3rem;
    }

    &__main {
        display: flex;
        flex-direction: column;
        width: 80%;
        height: 100%;
        padding: 0 1rem;
    }

    &__aside {
        width: 10%;
        height: 100%;

        h3 {
            margin-bottom: 1rem;
        }
    }

    &__action-window {
        position: relative;
        flex-grow: 1;
    }

    &__person,
    &__link {
        padding: 1rem 0;
        cursor: pointer;

        &:hover span {
            text-decoration: underline;
        }
    }

    &__person {
        &:last-child {
            display: flex;
            align-items: center;

            &:before {
                content: "";
                display: block;
                height: 20px;
                width: 20px;
                margin-right: 10px;
                background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgZmlsbD0iIzAwZmZhNSI+PHBhdGggZD0iTTEzIDdWMWwxMSAxMS0xMSAxMXYtNkgwVjd6Ii8+PC9zdmc+') center center no-repeat;
                background-size: contain;
            }
        }
    }

    &__overlay {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        padding: 3rem;
        background: rgba(0,0,0, .75);
        z-index: 30;
    }

    &__overlay-inner {
        width: 100%;
        height: 100%;
        background: lavender;
        display: flex;
        flex-direction: row-reverse;
        justify-content: center;
        overflow-y: scroll;
    }

    &__overlay-image {
        width: 25%;
        padding-top: 3rem;

        img {
            width: 100%;
            height: auto;
        }
    }

    &__overlay-content {
        padding: 2rem;
        width: 45%;
        color: #1b1b1b;

        h2, h3 {
            margin-top: 1rem;
        }

        td {
            padding-bottom: .35rem;
        }

        td:last-child {
            padding-left: .75rem;
        }
    }

    &__overlay-closer {
        position: absolute;
        top: 3rem;
        right: 3rem;
        padding: 1rem;
        cursor: pointer;
        color: #1b1b1b;
    }
}
</style>