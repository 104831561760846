<template>
    <div class="ck-video" @click="getPlayerData">
        <video ref="videoPlayer" class="video-js vjs-16-9"></video>
        <div class="ck-video__mute-button" @click="toggleMute" v-if="false">
            <span v-show="playerMuted">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="#00ffa5"><path d="M22 1.269l-18.455 22.731-1.545-1.269 3.841-4.731h-1.827v-10h4.986v6.091l2.014-2.463v-3.628l5.365-2.981 4.076-5.019 1.545 1.269zm-10.986 15.926v.805l8.986 5v-16.873l-8.986 11.068z"/></svg>
            </span>
            <span v-show="!playerMuted">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="#00ffa5"><path d="M5 17h-5v-10h5v10zm2-10v10l9 5v-20l-9 5zm17 4h-5v2h5v-2zm-1.584-6.232l-4.332 2.5 1 1.732 4.332-2.5-1-1.732zm1 12.732l-4.332-2.5-1 1.732 4.332 2.5 1-1.732z"/></svg>
            </span>
        </div>
    </div>
</template>

<script>
import videojs from 'video.js'

export default {
    name: 'VideoComponent',
    props: {
        options: {
            type: Object,
            default() {
                return {};
            }
        },
        disabled: {
            type: Boolean,
            default() {
                return false;
            }
        }
    },
    data() {
        return {
            player: null,
            playerMuted: false
        }
    },
    methods: {
        playVideo() {
            this.player.play()
        },
        getPlayerData() {
            if ( this.disabled ) return

            const playerData = {
                sources: this.player.currentSource(),
                time: this.player.currentTime()
            }

            this.$parent.$emit( 'playerData', playerData )
        },
        initPlayer() {
            this.disposePlayer()

            this.player = videojs( this.$refs.videoPlayer, this.options, function onPlayerReady() {
                console.log( 'onPlayerReady', this )
            } )
        },
        disposePlayer() {
            if ( this.player ) {
                this.player.dispose()
            }
        },
        setCurrentTime( time ) {
            this.player.currentTime( time )
        },
        setCurrentSource( source ) {
            this.player.src( source )
        },
        toggleMute() {
            console.log( this.player.muted() )
            if ( !this.player.muted() ) {
                this.player.muted( true )
                this.playerMuted = true
            } else {
                this.player.muted( false )
                this.playerMuted = false
            }
        }
    },
    mounted() {
        this.initPlayer()
        this.player.on( 'timeupdate', () => {
            const approximateTime = Math.round( this.player.currentTime() )

            if ( approximateTime === 327 ) {
                this.$parent.$emit( 'playerTimeEvent' )
            }

            if ( approximateTime === 334 ) {
                this.$parent.$emit( 'playerFinishedEvent' )
            }
        } )
    },
    beforeDestroy() {
        this.disposePlayer()
    }
}
</script>

<style lang="scss">
.ck-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;

    &__mute-button {
        position: absolute;
        top: -3rem;
        left: 0;
        width: 50px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }

    &__subtitle-reveal {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 50px;
        background: #1b1b1b;
    }
}
</style>