<template>
    <div class="ck-access ck-view">
        <div class="ck-access__intro ck-text-block">
            <h1>Access Verification</h1>
            <p class="ck-intro-text">Wait for your iris to be scanned.</p>
        </div>
        <div class="ck-access__iris-scanner">
            <div class="ck-access__iris-scanner-image">
                <img src="@/assets/scan.gif" alt="Eyes">
            </div>
        </div>
        <div class="ck-access__progress-container">
            <h3 v-show="!verifiedUser">Time to verify...</h3>
            <h3 v-show="verifiedUser">Successfully verified. Loading next step...</h3>
            <div class="ck-access__progress-bar-outer">
                <span class="ck-access__progress-bar-inner" :style="`width:${Math.round( progressValue )}%;`"></span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Access',
    data() {
        return {
            verifiedUser: false,
            progressValue: 0
        }
    },
    methods: {
        accessCamera() {
            navigator.mediaDevices.getUserMedia({ audio: false, video: true } )
                .then( ( stream ) => {
                    this.verifiedUser = true
                    this.$store.commit( 'setStream', stream )
                } )
                .catch( ( error ) => {
                    console.error( 'ACCESS ERROR: ', error )
                    clearInterval( this.progressTimer )
                    this.$store.commit( 'irisScanFailed', true )
                    this.$router.push( '/' )
                } )
        },
        handleProgress() {
            this.progressValue += 6.25 // 16 sek

            if ( this.progressValue === 100 ) {
                clearInterval( this.progressTimer )

                if ( this.verifiedUser ) {
                    this.$router.push('/call')
                } else {
                    this.$store.commit( 'irisScanFailed', true )
                    this.$router.push( '/' )
                }
            }
        }
    },
    mounted() {
        this.progressTimer = setInterval( this.handleProgress, 1000 )
        this.accessCamera()
    },
    beforeDestroy() {
        clearInterval( this.progressTimer )
    }
}
</script>

<style lang="scss">
.ck-access {
    &__iris-scanner {
        position: relative;
        margin: 3rem 0;
    }

    &__iris-scanner-image {
        position: relative;
        margin: 0 auto;
        width: 70%;

        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: #1b1b1b;
            opacity: .5;
        }

        img {
            width: 100%;
            height: auto;
        }
    }

    &__iris-scanner-notch {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 20px;
        height: 300px;
        background: #ff0084;
        opacity: .35;
    }

    &__progress-container {
        width: 80%;
        margin-top: 3rem;
        text-align: center;
    }

    &__progress-bar-outer {
        height: 35px;
        border: 2px solid #00ffa5;
        margin-top: 1rem;
    }

    &__progress-bar-inner {
        display: block;
        height: 100%;
        background: #00ffa5;
        transition: width .1s ease-in-out;
    }
}
</style>