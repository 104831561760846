const contentConfig = {
    persons: [
        {
            name: '@BoobyTrapBoy',
            content: '<h3>@BoobyTrapBoy</h3><h2>Oisín McCORMACK </h2> <table> <tr> <td>Known as:</td><td>Corvus/@BoobyTrapBoy</td></tr><tr> <td>Nationality:</td><td>Irish</td></tr><tr> <td>Born:</td><td>Derry, Northern Ireland</td></tr><tr> <td>Age:</td><td>27</td></tr></table> <h3>Personality:</h3> <p>Egotistical. Over-confident. E-boy. Likes to take risks. Gets himself into trouble easily.</p><h3>History:</h3> <p>Studied history. But went on to become a DJ in London.<br>Ended up broke and got a job working as a criminal.<br>Has had very few kills but bluffs his way around the criminal scene.<br>Made some connections in the field and started focusing on creating bombs.<br>Enrolled in the Irish criminal association of weapons and developed his first bomb back in 2014.<br></p><h3>Personal details: </h3> <p>Gets attracted to danger.<br>Likes to solve puzzles.<br>Expert in Bomb creation.<br>Vodka Lover.<br></p>',
            image: '/profiles/booby-trap-boy.png'
        },
        {
            name: '@Daggerswagger91',
            content: '<h3>@Daggerswagger91</h3><h2>Aida ABDALLAH</h2> <table> <tr> <td>Known as:</td><td>Sakin/@Daggerswagger91</td></tr><tr> <td>Nationality:</td><td>Lebanese-Saudi</td></tr><tr> <td>Born:</td><td>Cairo, Egypt</td></tr><tr> <td>Age:</td><td>29</td></tr></table> <h3>Personality:</h3> <p>Rebel. Stubborn. Smart. Intellectual. She hates compliments. She hates family gatherings. Hates babies, but might want to have one someday.</p><h3>History:</h3> <p>Involved in many uprisings across the Middle East.<br>Killed people in ways that are a little excessive, harsh or cruel at times.<br>Started working as an assassin to prove herself and get over her daddy issues.<br>Got recruited by the American spy forces and got fired for taking things too personal and getting out of control.<br></p><h3>Personal details: </h3> <p>Grew up with four brothers, has twenty cousins and are all very protective.<br>Have a mixed cultural background, but doesn’t believe in one religion.<br>Is nationalistic and progressive.<br>Raises crocodiles illegally.<br>Likes to use knives on her victims.<br></p>',
            image: '/profiles/dagger-swagger.png'
        },
        {
            name: '@i-slav-blood',
            content: '<h3>@i-slav-blood</h3><h2>Jaroslav WAGNER</h2> <table> <tr> <td>Known as:</td><td>Slav/@i-slav-blood</td></tr><tr> <td>Nationality:</td><td>Hungary</td></tr><tr> <td>Born:</td><td>Graz, Austria</td></tr><tr> <td>Age:</td><td>43</td></tr></table> <h3>Personality:</h3> <p>Aggressive, vindictive, and cruel. He operates for himself and finds it tough to work with others. Has been called crazy and proven to be so.</p><h3>History:</h3> <p>He is a dull accountant who is known for losing his temper with colleagues every now and then.<br>There are suspicions that he is a serial killer.<br>Got arrested 24 times and was never convicted with any felony.<br>Has been working alone since 1999 and prefers it that way.<br>Worked with all kinds of organizations.<br></p><h3>Personal details: </h3> <p>Loves everything about blood.<br>Will kill you and sleep in a minute.<br>Always happy.<br>Raising a pigeon.<br></p>',
            image: '/profiles/i-slav-blood.png'
        },
        {
            name: '@RedReign',
            content: '<h3>@RedReign</h3><h2>Irma ENKEMEIER</h2> <table> <tr> <td>Known as:</td><td>Irma/@RedReign</td></tr><tr> <td>Nationality:</td><td>German</td></tr><tr> <td>Born:</td><td>East-Germany</td></tr><tr> <td>Age:</td><td>50</td></tr></table> <h3>Personality:</h3> <p>Obsessive. Strict. Loud. Domineering. Insulting. Belittling. Loves dogs. Loves cat memes. Loves kids, but has a chip on her shoulder that she doesn’t have any.</p><h3>History:</h3> <p>Head of the family mafia. <br>Worked with the Russian and Arabic underworld. <br>Killed her own husband to take over the illegal business. Weapons dealer, state secrets for cash. Cover as an employee in a pharmaceutical company. <br>Worked with international assassins. <br></p><h3>Personal details: </h3> <p>Passion for power. <br>Hates patriarchal leaders. <br>Has 4 cats and 3 dogs. <br>Tech friendly. <br>Only trusts VR to protect her identity. <br></p>',
            image: '/profiles/red-reign.png'
        }
    ],
    links: [
        {
            name: 'C in C',
            content: '<h2>Contact Details</h2> <h3>Inquiry and collaborations</h3> <p> Rianne Cox <br><a href="mailto:R.Cox@edu.filmschule.de">R.Cox@edu.filmschule.de</a> </p><h3>Media and press</h3> <p> Eliane Eid <br><a href="mailto:E.Eid@edu.filmschule.de">E.Eid@edu.filmschule.de</a> <br><a href="https://www.instagram.com/eliane_eid" target="_blank">@eliane_eid</a> </p>'
        },
        {
            name: 'Internal',
            content: '<h2>Credits</h2> <h3>Actors</h3> <table> <tr> <td>Irma:</td><td>Christina "XaosPrincess" Kinne</td></tr><tr> <td>Sakin:</td><td>Eliane Eid</td></tr><tr> <td>Corvus:</td><td>Cathal Kerins</td></tr><tr> <td>Slav:</td><td>Tamas Joo</td></tr></table> <h3>Production</h3> <table> <tr> <td>Director:</td><td>Rianne Cox</td></tr><tr> <td>DOP:</td><td>Changzhen Zheng</td></tr><tr> <td>Production Design:</td><td>Alex Tamayo, Rianne Cox, Eliane Eid</td></tr><tr> <td>Visual Design:</td><td>Changzhen Zheng, Sarthak Bhasin, Alexandra Tamayo</td></tr><tr> <td>Producer:</td><td>Cathal Kerins</td></tr><tr> <td>Written by:</td><td>Rianne Cox, Eliane Eid, Cathal Kerins</td></tr><tr> <td>UI and software development:</td><td>Christina "XaosPrincess" Kinne, Sarthak Bhasin, Andy Junginger, Alexandra Tamayo, Eliane Eid </td></tr><tr> <td>Editors:</td><td>Changzhen Zheng, Cathal Kerins, Rianne Cox</td></tr><tr> <td>Lighting:</td><td>Changzhen Zheng</td></tr><tr> <td>Colouring:</td><td>Changzhen Zheng</td></tr><tr> <td>Foley:</td><td>Changzhen Zheng</td></tr><tr> <td>Sound Editing:</td><td>Changzhen Zheng</td></tr><tr> <td>VR Production:</td><td>Christina "XaosPrincess" Kinne</td></tr></table>'
        },
        {
            name: 'External',
            content: '<h3>Contributions</h3> <p>VR scenes shot on <a href="https://tivolicloud.com/" target="_blank">https://tivolicloud.com/</a></p><p>3D Artist (VR Avatar & Set Design): Ch0cka (<a href="https://twitter.com/notChocka" target="_blank">https://twitter.com/notChocka</a>)</p><p>Creative Commons Attribution 4.0 International (CC BY 4.0) "Mechanical Keyboard" <a href="http://kylermichaelson.com/" target="_blank">http://kylermichaelson.com/</a></p><h3>Project Licensing</h3> <p>This project is licensed under the Creative Commons Attribution-NonCommercial-ShareAlike 4.0 International Link of the license: <br><a rel="license" href="http://creativecommons.org/licenses/by-nc-sa/4.0/" target="_blank"><img alt="Creative Commons License" style="border-width:0" src="https://i.creativecommons.org/l/by-nc-sa/4.0/88x31.png"/></a><br/>This work is licensed under a <a rel="license" href="http://creativecommons.org/licenses/by-nc-sa/4.0/" target="_blank">Creative Commons Attribution-NonCommercial-ShareAlike 4.0 International License</a> </p>'
        },
        {
            name: 'Impressum',
            content: '<h2>Impressum</h2> <h3>Angaben gemaess § 5 TMG</h3> <p>Name und Anschrift <br>Rianne Cox<br>Bachemer Strasse 27, 50931, Koeln, Deutschland </p><h3>Verantwortlich fuer den Inhalt nach § 55 Abs. 2 RStV</h3> <p>Name und Anschrift <br>Rianne Cox<br>Bachemer Strasse 27, 50931, Koeln, Deutschland <br><a href="mailto:R.Cox@edu.filmschule.de">R.Cox@edu.filmschule.de</a> </p><h3>Urheberrecht</h3> <p>Haftung <br>Wir sind fuer die Kenntnis unserer Inhalter, die den allgemeinen Rechte, die Kontrolle nach § 7 Abs. 1 TMG, verwaltet. Alle Rechte werden mit dem gebotenen Wissen und nach bestem Wissen verwaltet. Sollten aus Deiner Sichtsicht gegen geltendes Recht oder oder sein Sein, teile uns das bitte mit. <br>Soweit wir auf unsere Inhalter mittels Hyperlink auf Internetseiten Dritter verweisen, koennen wir keine Gewaehr fuer die Fortwaehrende Aktualitaet, Richtigkeit und Vollstaendigkeit der verlinkten Rechte, da diese neuen eigenen Verantwortungsbereiche liegen. </p><h3>Urheberrechtshinweis</h3> <p>Die Texte, Bilder, Fotos, Videos oder Grafiken in unseren Inhalten wurden entsprechend gutgeschrieben. </p><h3>Kontakt</h3> <p>Name und Anschrift <br>E-Mail: <a href="mailto:R.Cox@edu.filmschule.de">R.Cox@edu.filmschule.de</a> </p>'
        }
    ],
    noprofile: '<h3>No profile established yet.</h3>'
}

export default contentConfig